import light_elephant from '../animals/light/elephant.png';
import dark_elephant from '../animals/dark/elephant.png';
import light_marmot from '../animals/light/marmot.png';
import dark_marmot from '../animals/dark/marmot.png';
import light_monkey from '../animals/light/monkey.png';
import dark_monkey from '../animals/dark/monkey.png';
import light_ferret from '../animals/light/ferret.png';
import dark_ferret from '../animals/dark/ferret.png';
import light_panda from '../animals/light/panda.png';
import dark_panda from '../animals/dark/panda.png';
import light_sheep from '../animals/light/sheep.png';
import dark_sheep from '../animals/dark/sheep.png';
import light_lion from '../animals/light/lion.png';
import dark_lion from '../animals/dark/lion.png';
import light_cat from '../animals/light/cat.png';
import dark_cat from '../animals/dark/cat.png';
import light_whale from '../animals/light/whale.png';
import dark_whale from '../animals/dark/whale.png';
import light_otter from '../animals/light/otter.png';
import dark_otter from '../animals/dark/otter.png';

function returnAnimal(animal, darkOrLight) {
  switch(animal) {
    case 'elephant':
      if (darkOrLight) {
        return `${dark_elephant}`;
      } else {
        return `${light_elephant}`;
      }
      break;
    case 'marmot':
      if (darkOrLight) {
        return `${dark_marmot}`;
      } else {
        return `${light_marmot}`;
      }
      break;
    case 'monkey':
      if (darkOrLight) {
        return `${dark_monkey}`;
      } else {
        return `${light_monkey}`;
      }
      break;
    case 'ferret':
      if (darkOrLight) {
        return `${dark_ferret}`;
      } else {
        return `${light_ferret}`;
      }
      break;
    case 'panda':
      if (darkOrLight) {
        return `${dark_panda}`;
      } else {
        return `${light_panda}`;
      }
      break;
    case 'sheep':
      if (darkOrLight) {
        return `${dark_sheep}`;
      } else {
        return `${light_sheep}`;
      }
      break;
    case 'lion':
      if (darkOrLight) {
        return `${dark_lion}`;
      } else {
        return `${light_lion}`;
      }
      break;
    case 'cat':
      if (darkOrLight) {
        return `${dark_cat}`;
      } else {
        return `${light_cat}`;
      }
      break;
    case 'whale':
      if (darkOrLight) {
        return `${dark_whale}`;
      } else {
        return `${light_whale}`;
      }
      break;
    case 'otter':
      if (darkOrLight) {
        return `${dark_otter}`;
      } else {
        return `${light_otter}`;
      }
      break;
    default:
      console.log('error');
  }
}
window.addEventListener('turbolinks:load', function() {
  if (document.URL.match(/fortunetellings\/free_fortune/)) {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const darkModeOn = darkModeMediaQuery.matches;
    const image = document.getElementsByTagName('img')[1];

    if (image != null) {
      const animal = image.src.split('/').slice(-1)[0].split('-')[0];
      image.src = returnAnimal(animal, window.matchMedia('(prefers-color-scheme: dark)').matches);
      darkModeMediaQuery.addListener((e) => {
        const darkModeOn = e.matches;
        image.src = returnAnimal(animal, darkModeOn);
      });
    }
  }
});
