window.addEventListener('turbolinks:load', function() {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const darkModeOn = darkModeMediaQuery.matches;

  if (document.URL.match('products') || document.URL.match('orders')) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    } else {
      document.getElementById('footer').children[0].style.backgroundColor = "rgba(0,0,0,0.1)";
      document.getElementById('wrapper').classList.add('shop-text');
    }

    darkModeMediaQuery.addListener((e) => {
      const darkModeOn = e.matches;
      if (darkModeOn) { // Dark
        document.getElementById('footer').children[0].style.backgroundColor = "rgba(255,255,255,0.1)";
      } else { // Light
        document.getElementById('footer').children[0].style.backgroundColor = "rgba(0,0,0,0.1)";
      }
    });
  } else {
    document.getElementById('wrapper').classList.remove('shop-text');
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    } else {
    }
  }
});
