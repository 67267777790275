// 日付入力フォームの日付の後ろに「日」を足すJavascript

function addHiCharacter(idName) {
  let daySelectBox = document.getElementById(idName);
  if (daySelectBox)  {
    for(let i=0; i < daySelectBox.children.length; i++) {
      daySelectBox.children[i].innerText = (i+1) + "日";
    }
  }
}

document.addEventListener('turbolinks:load', function() {
  addHiCharacter("resavation_start_3i");
  for(let i = 0; i<5; i++) {
    addHiCharacter(`resavation_fortunetelees_attributes_${i}_birth_3i`);
  }
});
