const regExp = /(?=.*resavations\/new\/reserve)(?!.*confirm).*$/;
function displayTableAndCoupons() {
  let month = ($('#resavation_start_2i').val()) ? $('#resavation_start_2i').val() : new Date().getMonth()+1;
  let day = ($('#resavation_start_3i').val()) ? $('#resavation_start_3i').val() : new Date().getDate();
  // $('#reservable-time').text("占い開始時間を選択してください");
  $.ajax({
    type: 'GET',
    url: '/reserve',
    data: { day: [month, day] },
    dataType: 'json'
  }).done(function(data) {
    displayTable(data);
    getCoupons();
  }).fail(function(result) {
    alert("エラーが発生しました。もう一度やり直してください");
  })
}

function displayTable(data) {
  $('#morning').empty();
  $('#afternoon').empty();
  $('#afternoon2').empty();
  $('#afternoon3').empty();
  $('#resavation-starttime').empty();
  $("#resavation-explanation").empty();
  $("#reservable-explanaiton").text("占い開始時間を選んでください。");
  generateTable("afternoon3", data);
  generateTable("afternoon2", data);
  generateTable("afternoon", data);
  generateTable("morning", data);
}

function getCoupons() {
  $.ajax({
    type: 'GET',
    url: '/coupons',
    dataType: 'json'
  }).done(function(res) {
    $("#coupons").empty();
    if (res.length != 0) {
      for(let k = 0; k < res.length; k++) {
        const label2 = document.createElement('label');
        const radioButton2 = document.createElement('input');
        const title = document.createElement('h5');
        title.innerHTML = "クーポンを使いますか？";
        radioButton2.type = "radio";
        radioButton2.name = "coupon";
        radioButton2.value = res[k].id;
        if (res[k].limitation) {
          label2.textContent = "30分無料クーポン";
        } else {
          label2.textContent = res[k].discount + "%引き";
        }
        document.getElementById("coupons").appendChild(title);
        document.getElementById("coupons").appendChild(radioButton2);
        document.getElementById("coupons").appendChild(label2);
        radioButton2.onchange = function() {
          $('#resavation_coupon').val(radioButton2.value);
        }
      }
      const label2 = document.createElement('label');
      const radioButton2 = document.createElement('input');
      radioButton2.type = "radio";
      radioButton2.name = "coupon";
      radioButton2.value = 0;
      label2.textContent = "利用しない";
      document.getElementById("coupons").appendChild(radioButton2);
      document.getElementById("coupons").appendChild(label2);
      radioButton2.onchange = function() {
        $('#resavation_coupon').val(radioButton2.value);
      }
    }
  }).fail(function(result) {
    alert("クーポンの取得に失敗しました。");
  });
}

function generateTable(id, data) {
  let monthAndDay = document.getElementById(id);
  let tbl = document.createElement("table");
  let tblBody = document.createElement("tbody");
  const timeTable = getIdForGenerateTable(id).table;
  let tableData = data.slice(getIdForGenerateTable(id).tableDataStart, getIdForGenerateTable(id).tableDataEnd)
  for(var i=0; i < tableData.length; i++) {
    const row = document.createElement("tr");
    for(var j=0; j < 2; j++) {
      const cell = document.createElement("td");
      let cellText;
      if (j == 0) {
        cellText = document.createTextNode(timeTable[i] + "~");
        if (tableData[i] == false) {
          cell.classList.add('unreserveble');
        }
      } else {
        const unreservableTime = document.createTextNode('×');
        const atag = document.createElement('a');
        const reservableTime = tableData[i];
        atag.href = "#";
        atag.text = "○";
        atag.classList.add("start-time");
        atag.dataset.time = tableData[i];
        atag.onclick = function(e) {
          e.preventDefault();
          $('a[data-time]').parent().css('backgroundColor', 'transparent');
          $('a[data-time]').parent().prev().css('backgroundColor', 'transparent');
          $(`a[data-time="${atag.dataset.time}"]`).parent().css("backgroundColor", "rgba(255, 254, 59, .7)");
          $(`a[data-time="${atag.dataset.time}"]`).parent().prev().css("backgroundColor", "rgba(255, 254, 59, .7)");
          $("#reservable-explanaiton").text("占ってもらう時間を選んでください。");
          $("#resavation_start").val(reservableTime);
          $("#resavation-explanation").text('選択した時間：');
          $("#resavation-starttime").text($('#resavation_start').val().match(/(.+?)T/)[1].replaceAll('-','/') + " " + $("#resavation_start").val().match(/T(.+):00\.000/)[1] + " ~");
          $("#reservable-time").empty();
          let disableFlg = false;
          for(k = 0; k < 4; k++) {
            const label = document.createElement('label');
            const radioButton = document.createElement('input');
            radioButton.type = "radio";
            radioButton.name = "selectableDuration";
            radioButton.value = k;
            radioButton.onchange = function() {
              $("#resavation_end").val(data[data.indexOf(reservableTime) + parseInt((radioButton.value), 10)]);
              let endtime = new Date($('#resavation_start').val());
              endtime.setMinutes(endtime.getMinutes() + (30 * (parseInt(radioButton.value) + 1)));
              $("#resavation-starttime").text($('#resavation_start').val().match(/(.+?)T/)[1].replaceAll('-','/') + " " + $("#resavation_start").val().match(/T(.+):00\.000/)[1] + " ~ " + String(endtime.getHours()) + ":" + ('00' + endtime.getMinutes()).slice(-2));
            }
            label.innerHTML = ((k + 1) * 30) + "分";
            if (disableFlg) {
              radioButton.disabled = true;
              label.style.color = "grey";
            } else {
              if (data[(data.indexOf(reservableTime) + k)] == false) {
                radioButton.disabled = true;
                label.style.color = "grey";
                disableFlg = true;
              }
            }
            document.getElementById("reservable-time").appendChild(radioButton);
            document.getElementById("reservable-time").appendChild(label);
          }
        };
        cellText = (tableData[i] != false) ? atag : unreservableTime;
        if (cellText == unreservableTime) {
          cell.classList.add("unreserveble")
        }
      }
      cell.appendChild(cellText);
      row.appendChild(cell);
    }
    tblBody.appendChild(row);
  }
  tbl.classList.add("table");
  tbl.appendChild(tblBody);
  monthAndDay.appendChild(tbl);
  tbl.setAttribute("border", "2");
}

function getIdForGenerateTable(id) {
  let tableData;
  switch(id) {
    case("disable1"):
      tableData = {table: ["0:00", "0:30", "1:00", "1:30", "2:00", "2:30", "3:00", "3:30", "4:00", "4:30", "5:00", "5:30", "6:00", "6:30", "7:00", "7:30", "8:00", "8:30"], tableDataStart: 0, tableDataEnd: 18}
      return tableData;
      break;
    case("morning"):
      tableData =  {table: [ "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00"], tableDataStart: 18, tableDataEnd: 25}
      return tableData;
      break;
    case("afternoon"):
      tableData =  {table: ["12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30"], tableDataStart: 25, tableDataEnd: 32}
      return tableData;
      break;
    case("afternoon2"):
      tableData =  {table: ["16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00"], tableDataStart: 32, tableDataEnd: 39}
      return tableData;
      break;
    case("afternoon3"):
      tableData =  {table: ["19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30"], tableDataStart: 39, tableDataEnd: 46}
      return tableData;
      break;
    case("disable2"):
      tableData =  {table: [ "23:00", "23:30", "24:00"], tableDataStart: 46, tableDataEnd: 48}
      return tableData;
      break;
    default:
      alert("Error");
  }
}

function displayAndHiddenChildFortuneForm() {
  // 一旦5人分のフォームをバックエンドからフロントエンドに渡し、formArrayに格納してから非表示にする
  // TODO: 現状、md以上のサイズは、子供の情報の入力欄がcol-12で展開されてしまうので、余裕があれば直す
  let form = document.getElementsByClassName('form-group-child');
  const cNum = document.getElementById('children_num');
  let partnerForm = document.getElementById('partner-attributes');
  let pf = document.getElementById('partner-form');
  const partnerCheck = document.getElementById('partner_check');
  const parentNode = document.getElementById('resavation_form');;
  const reference = parentNode.lastElementChild;
  partnerForm.remove();
  let formArray = Array();
  for(let i=0; i<5; i++) {
    formArray.push(form.item(form.length - 1));
    form.item(form.length - 1).remove()
  }
  formArray.reverse();

  // 子ども占いが選ばれたとき、子供の人数を入力する部分を表示し、別のものが選ばれたら消す
  let cfa = document.getElementById('child-fortune-attributes');
  let temp = cfa.children[0];
  cfa.children[0].remove();
  const fortunetellingType = document.getElementById('resavation_fortunetellingType');

  fortunetellingType.addEventListener('change', function() {
    if (fortunetellingType.value == "Child") {
      cfa.insertBefore(partnerForm, cfa.lastChild);
      cfa.insertBefore(temp, cfa.lastChild);
      for(let i=0; i<cNum.value; i++) {
        parentNode.insertBefore(formArray[i], reference);
      }
      for(let i=0; i<cNum.value; i++) {
        formArray.shift();
      }
    } else {
      if (cfa.children.length == 2) {
        cfa.children[0].remove();
        cfa.children[0].remove();
        let formCount = form.length;
        let tempFormArray = Array();
        for(let i=0; i<(formCount); i++){
          tempFormArray.push(form.item(form.length - 1));
          form.item(form.length - 1).remove();
        }
        tempFormArray.reverse();
        formArray = tempFormArray.concat(formArray);
        tempFormArray = [];
      }
    }
  });

  // cNumの値が変わったら表示したり、消したりする
  cNum.addEventListener('change', function() {
    let formCount = form.length;
    // cNumの数よりformCountが大きいとき、余剰分のformを減らす
    if (Number(cNum.value) < formCount) {
      let tempFormArray = Array();
      for(let i=0; i<(formCount - Number(cNum.value)); i++){
        tempFormArray.push(form.item(form.length - 1));
        form.item(form.length - 1).remove();
      }
      tempFormArray.reverse();
      formArray = tempFormArray.concat(formArray);
      tempFormArray = [];
    } 
    // cNumの数よりformCountが小さいとき、不足分のformを増やす
    else if (Number(cNum.value) > formCount) {
      for(let i=0; i<(Number(cNum.value) - formCount); i++) {
        parentNode.insertBefore(formArray[i], reference);
      }
      for(let i=0; i<(Number(cNum.value) - formCount); i++) {
        formArray.shift();
      }
    }
  });

  partnerCheck.addEventListener('change', function() {
    if (partnerCheck.value == "はい") {
      partnerForm.append(pf);
    } else {
      pf.remove();
    }
  });
}

function startAndEndTimeIsDecided() {
  const button = document.getElementById('to-reserve-confirm-button');
  const targetNode = document.getElementById('resavation-starttime');
  button.disabled = true;
  const observerOptions = {
    childList: true,
    attributes: true,
    subtree: true // 省略するか、false に設定すると、親ノードへの変更のみを監視する。
  }
  let observer = new MutationObserver(callback)

  function callback(mutationList, observer) {
    mutationList.forEach((mutation) => {
      if (mutation.target.innerHTML.match(/\d{4}\/\d{2}\/\d{2} \d{2}:\d{2} ~ \d{1,2}:\d{2}/)) {
        console.log(mutation.target.innerHTML);
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    });
  }
  observer.observe(targetNode, observerOptions);

}

document.addEventListener('turbolinks:load', function() {
  if (document.URL.match(regExp)) {
    displayTableAndCoupons();
    displayAndHiddenChildFortuneForm();
    startAndEndTimeIsDecided();
    $('#resavation_start_2i, #resavation_start_3i').on('change', function() {
      displayTableAndCoupons();
    });
    $('#resavation_start_1i').addClass('shadow');
    $('#resavation_start_2i').addClass('shadow');
    $('#resavation_start_3i').addClass('shadow');
  }
});
