import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

if (document.URL.match('/resavations/new')) {
  document.addEventListener('turbolinks:load', function() {
    let calendarEl = document.getElementById('calendar');
    let calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, interactionPlugin ],
      locale: 'ja',
      headerToolbar: {
        left: 'title',
        right: 'prev,next'
      },
      views: {
        dayGridMonth: {
          displayEventEnd: true
        }
      },
      displayEventTime: false,
      eventClick: function(info) {
        info.preventDefault;
        let date = String(info.event.start.getFullYear()) + "-" + String(info.event.start.getMonth()+1) + "-" + String(info.event.start.getDate());
        window.location.href = "/resavations/new/reserve" + "?date=" + date;
      }
    });

    calendar.render();
    $.ajax({
      type: 'GET',
      url: '/resavations/new',
      dataType: 'json'
    }).done(function(res) {
      console.log(res);
      for(let i=0; i<res.length; i++) {
        calendar.addEvent(res[i]);
      }
    }).fail(function(result) {
      alert ("予約可能な時間がありません");
    });
  });
}
