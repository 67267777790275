import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

document.addEventListener('turbolinks:load', function() {
  if (document.URL.match('shift')) {
    let calendarEl = document.getElementById('calendar');
    let calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
      timeZone: 'Asia/Tokyo',
      locale: 'ja',
      headerToolbar: {
        left: 'prev,next',
        right: 'today,dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title'
      },
      views: {
        dayGridMonth: {
          displayEventEnd: true
        }
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false
      }
    });
    calendar.render();

    $.ajax({
      type: 'GET',
      url: '/shifts',
      dataType: 'json'
    }).done(function(res) {
      for(let i=0; i<res.length; i++) {
        calendar.addEvent(res[i]);
      }
    }).fail(function(result) {
      alert("登録済みシフトデータ取得中にエラーが発生しました");
    });

    calendar.on('dateClick', function(info) {
      const year = info.date.getFullYear();
      const month = info.date.getMonth() + 1;
      const day = info.date.getDate();
      $.ajax({
        type: 'GET',
        url: '/shifts/new',
      }).done(function(res) {
        $('.modal-body').html(res);
        $('#shift_start_1i').val(year);
        $('#shift_start_2i').val(month);
        $('#shift_start_3i').val(day);
        $('#shift_end_1i').val(year);
        $('#shift_end_2i').val(month);
        $('#shift_end_3i').val(day);
        $('#modal').css("display", "block");
      }).fail(function(result) {
        alert("Error Occured");
        console.log(result);
      });
    });

    window.addEventListener('click', function(e) {
      if (e.target == modal) {
        modal.style.display = 'none';
      }
    });
  }
});
