$(document).on("turbolinks:load", function(){
  if(document.location.pathname.match(/cards/)) {

  var payjp = Payjp(process.env.PAYJP_PUBLIC_KEY);
  var elements = payjp.elements();

  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  /* ダークテーマの時 */
    var style = {
      base: {
        color: "white",
      },
    }
  } else {
  /* ライトテーマの時 */
    var style = {
      base: {
        color: "black",
      },
    }
  }
  var cardElement = elements.create('card', {style: style});
  cardElement.mount('#card-element');
  submit_btn = $('#info_submit');

  submit_btn.click(function(e) {
    e.preventDefault();

    payjp.createToken(cardElement).then(function(response) {
      if (response.error) {
        alert(response.error.message)
        regist_card.prop('disabled', false);
        return;
      } else {
        $("#card_token").append(
          `<input type="hidden" name="payjp_token" value=${response.id}>
           <input type="hidden" name="card_token" value=${response.card.id}>`
        );
        cardElement.clear()
        $('#card_form')[0].submit()
      }
    })
  });
  }
});
